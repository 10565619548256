import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { withPage } from "../PageContainer";
import Selectors from "../Selectors";
import ActionCreator from "../ActionCreator";
import { HeaderFontCss, ParagraphFontCss } from "../Utils/CssStyleTemplateUtil";
import { s } from "../Utils/Lang";
import { StaticImage } from "../Components/StaticImage";
import { isPhone, isPad } from "../Utils/WindowUtil";
import * as Widget from "../Components/Widget";
import { ArrowDown, ArrowUp } from "../Components/Icon";

class ProjectListPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: "all",
      en: false,
      isPanelOpen: true,
    };
  }
  componentDidUpdate() {
    window && window.scrollTo(0, 0);
  }

  componentDidMount() {
    if (this.props.location.pathname.indexOf("/en") !== -1) {
      this.setState({ en: true });
    }

    window.addEventListener("scroll", this._onScroll.bind(this));

    this.setState({ isPanelOpen: true });
    try {
      const hash = window.location.hash.toLowerCase();
      let _f = "all";

      switch (hash) {
        case "#all":
          _f = "all";
          break;
        case "#ec":
          _f = "ec";
          break;
        case "#iot":
          _f = "iot";
          break;
        case "#blockchain":
          _f = "blockchain";
          break;
        case "#startup":
          _f = "startup";
          break;
        case "#system":
          _f = "system";
          break;
        case "#branding":
          _f = "branding";
          break;
        case "#cloud":
          _f = "cloud";
          break;
        case "#consult":
          _f = "consult";
          break;
        default:
          _f = "all";
          break;
      }
      this.setState({ filter: _f });
    } catch (err) {
      this.setState({ filter: "all" });
    }
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this._onScroll.bind(this));
  }

  _onScroll() {
    if (isPad() || isPhone()) {
      if (this.state.isPanelOpen) {
        this.setState({ isPanelOpen: false });
      }
    }
  }

  render() {
    let { navActions } = this.props;
    let { projects } = this.props.pageContext;

    return (
      <Wrapper isPanelOpen={this.state.isPanelOpen}>
        <div
          className="introduction-section"
          style={{
            width: "100%",
          }}
        >
          {this.state.isPanelOpen && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
              onClick={() =>
                this.setState({ isPanelOpen: !this.state.isPanelOpen })
              }
            >
              <h1 className="title" style={{ marginBottom: 0, fontSize: 26 }}>
                {s("project-title")}
              </h1>
              {(isPad() || isPhone()) && (
                <ArrowComp
                  setOpen={s => {
                    this.setState({ isPanelOpen: s });
                  }}
                  isOpen={this.state.isPanelOpen}
                  extraStyle={{}}
                />
              )}
            </div>
          )}
          {this.state.isPanelOpen && <p>{s("project-introduction")}</p>}
          {this.state.isPanelOpen && (
            <Widget.Button
              css={`
                margin: 5px 0px 0px;
                margin-top: 30px !important;
                background-color: ${this.state.filter === "all"
                  ? "#f36a26"
                  : "#808080"};
                color: white;
              `}
              onClick={() => {
                this.setState({ filter: "all" });
                isPhone() &&
                  this.state.isPanelOpen &&
                  this.setState({ isPanelOpen: false });
              }}
            >
              {this.state.en ? "All" : "全部"}
            </Widget.Button>
          )}

          {this.state.isPanelOpen && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                width: "100%",
                marginTop: 10,
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Widget.Button
                css={`
                  margin-top: 0px !important;
                  background-color: ${this.state.filter === "ec"
                    ? "#f36a26"
                    : "#808080"};
                  color: white;
                  width: 48%;
                `}
                onClick={() => {
                  this.setState({ filter: "ec" });
                  isPhone() &&
                    this.state.isPanelOpen &&
                    this.setState({ isPanelOpen: false });
                }}
              >
                <h2
                  style={{
                    fontSize: 18,
                    margin: 0,
                    fontWeight: 400,
                    textAlign: "center",
                  }}
                >
                  {this.state.en ? "EC" : "客製電商"}
                </h2>
              </Widget.Button>

              <Widget.Button
                css={`
                  margin-top: 0px !important;
                  background-color: ${this.state.filter === "iot"
                    ? "#f36a26"
                    : "#808080"};
                  color: white;
                  width: 48%;
                `}
                onClick={() => {
                  this.setState({ filter: "iot" });
                  isPhone() &&
                    this.state.isPanelOpen &&
                    this.setState({ isPanelOpen: false });
                }}
              >
                <h2
                  style={{
                    fontSize: 18,
                    margin: 0,
                    fontWeight: 400,
                    textAlign: "center",
                  }}
                >
                  {this.state.en ? "IoT" : "物聯網"}
                </h2>
              </Widget.Button>

              <Widget.Button
                css={`
                  margin-top: 10px !important;
                  background-color: ${this.state.filter === "blockchain"
                    ? "#f36a26"
                    : "#808080"};
                  color: white;
                  width: 48%;
                `}
                onClick={() => {
                  this.setState({ filter: "blockchain" });
                  isPhone() &&
                    this.state.isPanelOpen &&
                    this.setState({ isPanelOpen: false });
                }}
              >
                <h2
                  style={{
                    fontSize: 18,
                    margin: 0,
                    fontWeight: 400,
                    textAlign: "center",
                  }}
                >
                  {this.state.en ? "BlockChain" : "區塊鏈"}
                </h2>
              </Widget.Button>

              <Widget.Button
                css={`
                  margin-top: 10px !important;
                  background-color: ${this.state.filter === "startup"
                    ? "#f36a26"
                    : "#808080"};
                  color: white;
                  width: 48%;
                `}
                onClick={() => {
                  this.setState({ filter: "startup" });
                  isPhone() &&
                    this.state.isPanelOpen &&
                    this.setState({ isPanelOpen: false });
                }}
              >
                <h2
                  style={{
                    fontSize: 18,
                    margin: 0,
                    fontWeight: 400,
                    textAlign: "center",
                  }}
                >
                  {this.state.en ? "StartUp" : "新創產品"}
                </h2>
              </Widget.Button>

              <Widget.Button
                css={`
                  margin: 5px 0px 0px;
                  margin-top: 10px !important;
                  background-color: ${this.state.filter === "system"
                    ? "#f36a26"
                    : "#808080"};
                  color: white;
                  width: 48%;
                `}
                onClick={() => {
                  this.setState({ filter: "system" });
                  isPhone() &&
                    this.state.isPanelOpen &&
                    this.setState({ isPanelOpen: false });
                }}
              >
                <h2
                  style={{
                    fontSize: 18,
                    margin: 0,
                    fontWeight: 400,
                    textAlign: "center",
                  }}
                >
                  {this.state.en ? "System" : "流程系統"}
                </h2>
              </Widget.Button>

              <Widget.Button
                css={`
                  margin: 5px 0px 0px;
                  margin-top: 10px !important;
                  background-color: ${this.state.filter === "branding"
                    ? "#f36a26"
                    : "#808080"};
                  color: white;
                  width: 48%;
                `}
                onClick={() => {
                  this.setState({ filter: "branding" });
                  isPhone() &&
                    this.state.isPanelOpen &&
                    this.setState({ isPanelOpen: false });
                }}
              >
                <h2
                  style={{
                    fontSize: 18,
                    margin: 0,
                    fontWeight: 400,
                    textAlign: "center",
                  }}
                >
                  {this.state.en ? "Branding" : "品牌概念"}
                </h2>
              </Widget.Button>

              <Widget.Button
                css={`
                  margin: 5px 0px 0px;
                  margin-top: 10px !important;
                  background-color: ${this.state.filter === "cloud"
                    ? "#f36a26"
                    : "#808080"};
                  color: white;
                  width: 48%;
                `}
                onClick={() => {
                  this.setState({ filter: "cloud" });
                  isPhone() &&
                    this.state.isPanelOpen &&
                    this.setState({ isPanelOpen: false });
                }}
              >
                <h2
                  style={{
                    fontSize: 18,
                    margin: 0,
                    fontWeight: 400,
                    textAlign: "center",
                  }}
                >
                  {this.state.en ? "Cloud Service" : "雲端服務"}
                </h2>
              </Widget.Button>

              <Widget.Button
                css={`
                  margin: 3px 0px 0px;
                  margin-top: 10px !important;
                  background-color: ${this.state.filter === "consult"
                    ? "#f36a26"
                    : "#808080"};
                  color: white;
                  width: 48%;
                `}
                onClick={() => {
                  this.setState({ filter: "consult" });
                  isPhone() &&
                    this.state.isPanelOpen &&
                    this.setState({ isPanelOpen: false });
                }}
              >
                <h2
                  style={{
                    fontSize: 18,
                    margin: 0,
                    fontWeight: 400,
                    textAlign: "center",
                  }}
                >
                  {this.state.en ? "Consultant" : "顧問諮詢"}
                </h2>
              </Widget.Button>
            </div>
          )}

          {this.state.isPanelOpen && (
            <Widget.Button
              css={`
                margin: 5px 0px 0px;
                margin-top: 10px !important;
                background-color: #d1370d;
              `}
              onClick={() =>
                window &&
                window.open(
                  "https://revteltech.pse.is/goto_revtel_medium_from_website",
                  "_blank"
                )
              }
            >
              <h2
                style={{
                  fontSize: 18,
                  margin: 0,
                  fontWeight: 400,
                  textAlign: "center",
                }}
              >
                {this.state.en ? "More (Medium)" : "更多開發分享 ( MEDIUM )"}
              </h2>
            </Widget.Button>
          )}
          {isPhone() && !this.state.isPanelOpen && (
            <ArrowComp
              setOpen={s => this.setState({ isPanelOpen: s })}
              isOpen={this.state.isPanelOpen}
              extraStyle={{ marginTop: 20 }}
            />
          )}
        </div>
        <div className="project-list-section">
          <div
            className="project-items-container"
            style={
              isPhone() && !this.state.isPanelOpen ? { paddingTop: 50 } : {}
            }
          >
            {projects
              .filter(item => {
                if (this.state.filter === "all") {
                  return true;
                } else {
                  return item.tags.indexOf(this.state.filter) !== -1;
                }
              })
              .map((item, idx) => {
                let _link = null;
                if (item.detail) {
                  _link = "/project/" + item.detail.id;
                } else if (item.linkURL) {
                  _link = item.linkURL;
                }
                console.log("... " + item[`title_tw`] + ", " + _link);
                return _link ? (
                  <a
                    key={idx}
                    className="project-item"
                    target="_blank"
                    href={_link}
                    aria-label={
                      item.detail
                        ? "https://www.revtel.tech/project/" + item.detail.id
                        : item.linkURL
                        ? item.linkURL
                        : item.id
                    }
                    style={{
                      cursor:
                        (item.description_tw && this.props.lang === "tw") ||
                        item.detail ||
                        item.linkURL
                          ? "pointer"
                          : "not-allowed",
                      padding: 2,
                      backgroundColor: "#EEEEEE",
                    }}
                  >
                    <ProjectItem item={item} key={idx} />
                  </a>
                ) : (
                  <p
                    key={idx}
                    className="project-item"
                    target="_blank"
                    aria-label={
                      item.detail
                        ? "https://www.revtel.tech/project/" + item.detail.id
                        : item.linkURL
                        ? item.linkURL
                        : item.id
                    }
                    style={{
                      cursor:
                        (item.description_tw && this.props.lang === "tw") ||
                        item.detail ||
                        item.linkURL
                          ? "pointer"
                          : "not-allowed",
                      padding: 2,
                      backgroundColor: "#EEEEEE",
                    }}
                  >
                    <ProjectItem item={item} key={idx} lang={this.props.lang} />
                  </p>
                );
              })}
          </div>
        </div>
      </Wrapper>
    );
  }
}
const ArrowComp = ({ setOpen, isOpen, extraStyle = {} }) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
        ...extraStyle,
      }}
      onClick={() => setOpen(!isOpen)}
    >
      <div
        style={{
          backgroundColor: "#E8D5C4",
          width: 45,
          height: 45,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: 22.5,
        }}
      >
        {isOpen && <ArrowUp size="32" color="#F0A04B" />}
        {!isOpen && <ArrowDown size="32" color="#F0A04B" />}
      </div>
    </div>
  );
};

const Wrapper = styled.div`
  position: relative;
  display: flex;

  @media screen and (max-width: 900px) {
    flex-direction: column;
  }

  .introduction-section {
    background-color: #f1f1f1;
    padding: 30px;
    color: #4e4e4e;
    display: flex;
    flex-direction: column;
    flex-basis: 360px;
    flex-grow: 0;
    flex-shrink: 0;
    min-height: 90px;

    & h2 {
      letter-spacing: 2px;
      font-size: 26px;
      ${HeaderFontCss}
      margin-bottom: 20px;
    }

    & p {
      font-size: 14px;
      ${ParagraphFontCss}
      white-space: pre-wrap;
      text-align: justify;
      line-height: 20px;
    }

    & button {
      margin-top: 40px;
      align-self: flex-start;
    }
  }

  .project-list-section {
    flex-grow: 1;
    background-color: #f1f1f1;

    & .filter-bar-container {
      min-height: 50px;
      display: flex;
      align-items: stretch;
    }
    & .project-items-container {
      display: flex;
      flex-wrap: wrap;
      & .project-item {
        flex-basis: calc(100% / 2);
      }
    }
  }

  @media screen and (max-width: 900px) {
    .introduction-section {
      position: fixed;
      z-index: 5;

      height: ${props => (props.isPanelOpen ? "570px" : "40px")};

      display: flex;
      flex-direction: column;
      justify-content: center;

      padding: 30px 40px;
      flex-basis: initial;

      flex-grow: 1;
      & h2 {
        letter-spacing: 2px;
        text-align: left;
      }

      & button {
        align-self: center;
      }
    }

    .project-list-section {
      margin-top: ${props => (props.isPanelOpen ? "570px" : "40px")};
    }
  }

  @media screen and (max-width: 500px) {
    .introduction-section {
      padding: 20px 25px 20px;
      & button {
        margin-top: 30px;
      }
    }
    .project-list-section {
      & .filter-bar-container {
      }
      & .project-items-container {
        & .project-item {
          flex-basis: 100%;
        }
      }
    }
  }
`;

class ProjectItem extends Component {
  render() {
    let { item, lang } = this.props;

    return (
      <ProjectItemWrapper style={{ position: "relative" }}>
        <div
          style={{
            backgroundColor: "#eeeeee",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={"/images/projects/" + item.cover_img}
            alt={item.alt}
            style={{ backgroundSize: "cover", height: 300, width: "100%" }}
          />
        </div>
        <div className="info-container">
          <h3 className="title" style={{ fontSize: 20 }}>
            {item[`title_${lang}`]}
          </h3>
          <div className="date">{item.created}</div>
          {lang === "tw" && (item.detail || item.linkURL) && (
            <p
              style={{
                textDecoration: "underline",
                color: "lightgrey",
                fontSize: 16,
              }}
            >
              More Info
            </p>
          )}
        </div>
        {lang === "tw" && (item.detail || item.linkURL) && (
          <div
            style={{
              position: "absolute",
              right: 0,
              top: 0,
              width: 35,
              height: 35,
              backgroundColor: "#EEEEEE",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img src="/images/arrow-right.png" width="25" height="25" />
          </div>
        )}
      </ProjectItemWrapper>
    );

    return (
      <ProjectItemWrapper style={{ position: "relative" }}>
        <StaticImage
          name={item.cover_img}
          alt={item.alt}
          style={{ backgroundSize: "cover", height: 250 }}
        />
        <div className="info-container">
          <h3 className="title" style={{ fontSize: 20 }}>
            {item[`title_${lang}`]}
          </h3>
          <div className="date">{item.created}</div>
          {lang === "tw" && (item.detail || item.linkURL) && (
            <p
              style={{
                textDecoration: "underline",
                color: "lightgrey",
                fontSize: 16,
              }}
            >
              More Info
            </p>
          )}
        </div>
        {lang === "tw" && (item.detail || item.linkURL) && (
          <div
            style={{
              position: "absolute",
              right: 0,
              top: 0,
              width: 35,
              height: 35,
              backgroundColor: "white",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 3,
              backgroundColor: "#EEEEEE",
            }}
          >
            <img src="/images/arrow-right.png" width="25" height="25" />
          </div>
        )}
      </ProjectItemWrapper>
    );
  }
}

const ProjectItemWrapper = styled.div`
  position: relative;
  height: 100%;

  & .info-container {
    position: absolute;
    bottom: 0px;
    left: 0px;
    height: 50%;
    width: 100%;
    background-image: linear-gradient(
      0deg,
      #4e4e4e 0%,
      rgba(170, 170, 170, 0.01) 89%
    );
    padding: 0px 20px 15px 20px;
    display: flex;
    align-items: flex-end;
    font-size: 20px;
    color: white;
    letter-spacing: 1px;
    ${HeaderFontCss}
    justify-content: space-between;

    & .title {
    }
    & .date {
    }
  }
`;

ProjectItem = connect(
  (state, ownProps) => ({
    lang: Selectors.getLang(state),
  }),
  ActionCreator
)(ProjectItem);

export default withPage(
  connect((state, ownProps) => ({ ownProps }), ActionCreator)(ProjectListPage)
);
